<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less" >
html,body,h3{
  margin: 0;
  padding: 0
}
</style>

