<template>
  <div>
    <el-container style="height: 100vh">
      <el-aside style="width: auto;"><sidebar /></el-aside>
      <el-container>
        <el-header><headbar /></el-header>
        <el-main><app-main /></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { AppMain, Sidebar, Headbar } from './components'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Headbar,
    Sidebar
  }

}
</script>

<style lang="scss" scoped>
.el-header{
  padding: 0
}
.el-main{
  padding: 0;
  margin: 0;
}
</style>
