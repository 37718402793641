import request from './mapiRequest'
import global from './../config/global'

/**
 *  获取 mapi token
 */
export function getToken(authCode) {
  return request({
    url: 'oauth2/authorize/access_token',
    method: 'post',
    data: {
      app_id: global.app_id,
      secret: global.secret,
      auth_code: authCode
    }
  })
}

/**
 *  获取 地域信息
 */
export function getAddress(data) {
  return request({
    url: 'gw/ad_social/v2/supplement_order/target_info',
    method: 'post',
    data: { advertiser_id: data }
  })
}

/**
 * 星视频--查询订单的投后数据 接口
 */
export function starVideosOrder(data) {
  return request({
    url: 'v1/ad_social/task/list',
    method: 'post',
    data: data
  })
}

/**
 * 星视频--视频订单分天数据获取
 */
export function starVideosDailyData(data) {
  return request({
    url: 'gw/ad_social/v1/star/video/order/daily_data',
    method: 'post',
    data: data
  })
}

/**
 * 星任务（视频）--查询订单的投后数据
 */
export function starTaskVideosOrderList(data) {
  return request({
    url: 'gw/ad_social/v1/quick_task/order/list',
    method: 'post',
    data: data
  })
}

/**
 * 星任务（视频）--订单分天数据接口
 */
export function starTaskVideosDailyData(data) {
  return request({
    url: 'gw/ad_social/v1/quick_task/video/order/daily_data',
    method: 'post',
    data: data
  })
}

/**
 * 流量助推查询接口--星视频、星任务-视频流量助推投后数据查询接口
 */
export function flowPushData(data) {
  return request({
    url: 'v1/ad_social/supplement_order/list',
    method: 'post',
    data: data
  })
}

/**
 * 流量助推查询接口--查询实时数据接口
 */
export function flowPushRealTime(data) {
  return request({
    url: 'gw/ad_social/v1/supplement_order/realtime/list',
    method: 'post',
    data: data
  })
}
