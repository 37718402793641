import axios from 'axios'
import Cookie from 'js-cookie'
import global from './../config/global'

const instance = axios.create({
  baseURL: global.mapiBaseApi,
  timeout: 1000
})

// 设置请求拦截器，每次请求前都会执行
instance.interceptors.request.use((config) => {
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  // 在请求头添加 Access-Token 和 Content-Type
  // if (getToken() && !isToken) {
  // config.headers['Access-Token'] = getToken();
  const access_token = Cookie.get('myCookie')
  config.headers['Access-Token'] = access_token
  config.headers['Content-Type'] = 'application/json'
  // }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

export default instance
