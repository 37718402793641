const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  access_token: state => state.user.access_token,
  access_token_expires_in: state => state.user.access_token_expires_in,
  advertiser_id: state => state.user.advertiser_id,
  refresh_token: state => state.user.refresh_token,
  refresh_token_expires_in: state => state.user.refresh_token_expires_in,
  user_id: state => state.user.user_id,
  tab: state => state.tab.isCollapse
}
export default getters
