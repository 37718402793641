<template>
  <el-menu
    default-active="this.$route.path"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    router
  >
    <h3>
      <img v-if="isCollapse" src="./../../../assets/images/mapi.png" alt="logo">
      <span v-else>MAPI</span>
    </h3>
    <el-menu-item v-for="item in noChildren" :key="item.name" :index="item.name" @click="clickMenu">
      <i :class="`el-icon-${item.icon}`" />
      <span slot="title">{{ item.label }}</span>
    </el-menu-item>
    <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
      <template slot="title">
        <i :class="`el-icon-${item.icon}`" />
        <span slot="title">{{ item.label }}</span>
      </template>
      <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
        <el-menu-item :index="subItem.path">{{ subItem.label }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>

<script>

export default {
  data() {
    return {
      menuData: [
        {
          path: '/index',
          name: 'index',
          label: '首页',
          icon: 's-home',
          url: '@/views/index'
        },
        {
          label: '星视频',
          icon: 'video-play',
          children: [
            {
              path: '/StarVideoOrder',
              name: 'StarVideoOrder',
              label: '订单投后数据',
              url: '@/views/StarVideoOrder'
            },
            {
              path: '/StarVideoDailyData',
              name: 'StarVideoDailyData',
              label: '订单分天数据',
              url: '@/views/StarVideoDailyData'
            },
            {
              path: '/StarVideoFlowPush',
              name: 'StarVideoFlowPush',
              label: '一体化导出',
              url: '@/views/StarVideoFlowPush'
            },
            {
              path: '/StarVideoRealTime',
              name: 'StarVideoRealTime',
              label: '助推分时导出',
              url: '@/views/StarVideoRealTime'
            }
          ]
        },
        {
          label: '星任务(视频)',
          icon: 'notebook-2',
          children: [
            {
              path: '/StarTaskVideoOrderData',
              name: 'StarTaskVideoOrderData',
              label: '订单投后数据',
              url: '@/views/StarTask/Video/OrderData'
            },
            {
              path: '/StarTaskVideoDailyData',
              name: 'StarTaskVideoDailyData',
              label: '订单分天数据',
              url: '@/views/StarTask/Video/OrderData'
            },
            {
              path: '/StarTaskVideoIntegration',
              name: 'StarTaskVideoIntegration',
              label: '一体化导出',
              url: '@/views/StarTask/Video/Integration'
            },
            {
              path: '/StarTaskVideoIntegrationRealTime',
              name: 'StarTaskVideoIntegrationRealTime',
              label: '助推分时导出',
              url: '@/views/StarTask/Video/StarTaskVideoIntegrationRealTime'
            },
          ]
        },
        {
          label: '流量助推2.0',
          icon: 'data-line',
          children: [
            {
              path: '/FlowPushData',
              name: 'FlowPushData',
              label: '助推投后数据',
              url: '@/views/FlowPushData'
            },
            {
              path: '/FlowPushRealTime',
              name: 'FlowPushRealTime',
              label: '助推实时数据',
              url: '@/views/FlowPushRealTime'
            },
            {
              path: '/FlowPushDataCreativeInfo',
              name: 'FlowPushDataCreativeInfo',
              label: '助推创意信息',
              url: '@/views/FlowPushDataCreativeInfo'
            }
          ]
        },
        {
          label: '任务管理',
          icon: 'finished',
          children: [
            {
              path: '/Mission',
              name: 'Mission',
              label: '任务列表',
              url: '@/views/Mission'
            }
          ]
        },
        // {
        //   label: '测试页面',
        //   icon: 'eleme',
        //   children: [
        //     {
        //       path: '/Test1',
        //       name: 'workService',
        //       label: '星视频一体化导出',
        //       url: '@/Test/Test1'
        //     }
        //   ]
        // }
      ]
    }
  },
  computed: {
    // 没有子菜单
    noChildren() {
      return this.menuData.filter(item => !item.children)
    },
    // 有子菜单
    hasChildren() {
      return this.menuData.filter(item => item.children)
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse
    }
  },
  methods: {
    // 点击菜单
    clickMenu(item) {
      // 当页面路由与跳转的路由不一致才允许跳转
      if (this.$route.path !== item.path && !(this.$route.path === '/index' && (item.path ===
          '/'))) {
        this.$router.push(item.path)
      }
      // $route表示当前页面路由，$router表示整个router实例
    }
  }
}
</script>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100%;
  border-right: none;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 8vh; /* 设置父元素高度 */
  }
}
</style>
