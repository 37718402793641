import Vue from 'vue'
import App from './App.vue'
// import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import store from './store'
import router from './router'
import Pagination from '@/components/Pagination/index.vue';
Vue.use(ElementUI)

Vue.config.productionTip = false
// 2. 全局注册 Pagination 组件
Vue.component('Pagination', Pagination);
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
