import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import('@/views/index'),
        name: 'Index',
        props: true,
        meta: { title: '首页', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarVideoOrder',
        component: () => import('@/views/StarVideoOrder'),
        name: 'StarVideoOrder',
        meta: { title: '订单投后数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarVideoDailyData',
        component: () => import('@/views/StarVideoDailyData'),
        name: 'StarVideoDailyData',
        meta: { title: '订单分天数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarVideoFlowPush',
        component: () => import('@/views/StarVideoFlowPush'),
        name: 'StarVideoFlowPush',
        meta: { title: '订单-流量助推数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarVideoRealTime',
        component: () => import('@/views/StarVideoRealTime'),
        name: 'StarVideoRealTime',
        meta: { title: '订单-助推分时导出', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarTaskVideoOrderData',
        component: () => import('@/views/StarTask/Video/OrderData'),
        name: 'StarTaskVideoOrderData',
        meta: { title: '视频订单投后数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarTaskVideoDailyData',
        component: () => import('@/views/StarTask/Video/DailyData'),
        name: 'StarTaskVideoDailyData',
        meta: { title: '视频订单分天数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarTaskVideoIntegration',
        component: () => import('@/views/StarTask/Video/Integration'),
        name: 'StarTaskVideoDailyData',
        meta: { title: '视频订单一体化导出', icon: 'dashboard', affix: true }
      },
      {
        path: '/StarTaskVideoIntegrationRealTime',
        component: () => import('@/views/StarTask/Video/IntegrationRealTime'),
        name: 'StarTaskVideoIntegrationRealTime',
        meta: { title: '助推分时导出', icon: 'dashboard', affix: true }
      },
      {
        path: '/FlowPushData',
        component: () => import('@/views/FlowPushData'),
        name: 'FlowPushData',
        meta: { title: '助推投后数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/FlowPushRealTime',
        component: () => import('@/views/FlowPushRealTime'),
        name: 'FlowPushRealTime',
        meta: { title: '助推实时数据', icon: 'dashboard', affix: true }
      },
      {
        path: '/FlowPushDataCreativeInfo',
        component: () => import('@/views/FlowPushDataCreativeInfo'),
        name: 'FlowPushDataCreativeInfo',
        meta: { title: '助推创意信息', icon: 'dashboard', affix: true }
      },
      {
        path: '/Mission',
        component: () => import('@/views/Mission'),
        name: 'Mission',
        meta: { title: '任务管理', icon: 'dashboard', affix: true }
      },
      {
        path: '/Test1',
        component: () => import('@/views/Test/Test1'),
        name: 'Test1',
        meta: { title: '测试1', icon: 'dashboard', affix: true }
      }
    ]
  }
]

// 防止连续点击多次路由报错
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  base: process.env.VUE_APP_CONTEXT_PATH,
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
