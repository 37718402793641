<template>
  <div class="header-container">
    <div class="l-content">
      <el-button :icon="isIcon ? 'el-icon-s-fold' : 'el-icon-s-unfold'" style="padding: 0;height: 60px;width: 60px;border: none;font-size: 20px" @click="handleMenu" />
      <!-- 面包屑 -->
      <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
      <span class="text">首页</span>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img class="user" src="./../../../assets/images/user.png" alt="user.png">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>个人中心</el-dropdown-item>
          <el-dropdown-item v-if="advertiserId!==0" @click.native="copyAdvertiserId">快手ID：{{ advertiserId }}</el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Cookie from 'js-cookie'
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      isIcon: true,
      advertiserId: undefined
    }
  },
  mounted() {
    this.advertiserId = Cookie.get('myID')
  },
  methods: {
    handleMenu() {
      this.$store.commit('collapseMenu')
      this.isIcon = !(this.$store.state.tab.isCollapse)
    },
    logout() {
      console.log('用户已退出')
      // 删除Cookie
      Cookies.remove('myCookie')
      this.$router.push('/')
    },
    copyAdvertiserId() {
      const el = document.createElement('textarea')
      el.value = this.advertiserId
      el.style.position = 'fixed'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$message.success('复制成功')
    }
  }
}

</script>
<style lang="less" scoped>
.header-container {
  padding: 0 20px 0 0;
  background-color: #fff;
  height: 60px;
  display:flex;
  justify-content:space-between;
  align-items: center;
  /* 添加阴影效果 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  .text{
    color:#fff;
    font-size:14px;
    margin-left: 10px;
  }
  .l-content{
    display: flex;
    align-items: center;
  }
  .r-content{
    .user{
      width:40px;
      height:40px;
      border-radius:50%;
    }
  }
}
</style>
